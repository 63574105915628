





































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselEnquiryDocumentUpdateModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselEnquiryDocumentUpdateDialog extends Validation {
  @Prop(String) private vesselEnquiryDocumentId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VesselEnquiryDocumentUpdateModel = {
    reference: "",
    filename: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.VesselEnquiryDocumentService.apiVesselenquirydocumentVesselEnquiryDocumentIdGet(
        this.vesselEnquiryDocumentId
      );
      this.model = response.data;
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to fetch vessel enquiry document"
      );
      this.syncedDialog = false;
    }
  }

  private async onDocumentUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      await Api.VesselEnquiryDocumentService.apiVesselenquirydocumentVesselEnquiryDocumentIdPut(
        this.vesselEnquiryDocumentId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Document updated");
      this.$emit("document-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update document");
    } finally {
      this.syncedDialog = false;
    }
  }
}
