

























import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import VesselEnquiryDocumentCreateDialog from "@/components/dialogs/VesselEnquiryDocumentCreateDialog.vue";
import VesselEnquiryDocumentUpdateDialog from "@/components/dialogs/VesselEnquiryDocumentUpdateDialog.vue";
import VesselEnquiryDocumentTable from "@/components/vessel/enquiry/VesselEnquiryDocumentTable.vue";
import { VesselEnquiryDocumentModel } from "@/api/generated";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    VesselEnquiryDocumentTable,
    VesselEnquiryDocumentCreateDialog,
    VesselEnquiryDocumentUpdateDialog
  }
})
export default class VesselEnquiryDocuments extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  private selectedVesselEnquiryDocumentId = "";
  private refreshKey = false;
  private createDialog = false;
  private updateDialog = false;

  private getDocuments() {
    this.refreshKey = !this.refreshKey;
  }

  private onCreateDocument() {
    this.createDialog = true;
  }

  private onEditDocument(document: VesselEnquiryDocumentModel) {
    if (!document.vesselEnquiryDocumentId) {
      return;
    }

    this.selectedVesselEnquiryDocumentId = document.vesselEnquiryDocumentId;
    this.updateDialog = true;
  }

  private async onDownloadDocument(document: VesselEnquiryDocumentModel) {
    if (!document.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      document.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, document.filename);
  }

  private async onDeleteDocument(document: VesselEnquiryDocumentModel) {
    if (!document.vesselEnquiryDocumentId) {
      return;
    }

    try {
      await Api.VesselEnquiryDocumentService.apiVesselenquirydocumentVesselEnquiryDocumentIdDelete(
        document.vesselEnquiryDocumentId
      );
      snackbarModule.setSnackbarMessage("Document deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete document");
    }

    this.getDocuments();
  }
}
