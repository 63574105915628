





































import {
  Vue,
  Component,
  PropSync,
  Ref,
  Watch,
  Prop
} from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import VesselEnquiryDocumentCreateModel from "@/models/vesselEnquiryDocumentCreateModel";
import FormDataFactory from "@/utility/formDataFactory";
import { apiClient } from "@/services/apiService";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberCertificateCreateDialog extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VesselEnquiryDocumentCreateModel = {
    vesselEnquiryId: "",
    document: null,
    reference: "",
    filename: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onCreateDocument() {
    if (!this.form.validate()) {
      return;
    }

    this.model.vesselEnquiryId = this.vesselEnquiryId;
    this.model.filename = this.model.document?.name ?? "";

    try {
      this.loading = true;
      const formData = FormDataFactory.Create(this.model);
      await apiClient.post("api/vesselEnquiryDocument", formData);
      snackbarModule.setSnackbarMessage("Vessel Enquiry document created");
      this.$emit("document-created");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to create vessel enquiry document"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
