




































import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import nameof from "@/utility/nameof";
import Api from "@/api";
import { VesselEnquiryDocumentModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselEnquiryDocumentTable extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  @Prop({default: "Vessel Enquiry Documents"}) private title?: string;

  private loading = false;
  private documents: Array<VesselEnquiryDocumentModel> = [];
  private headers: Array<DataTableHeader<VesselEnquiryDocumentModel>> = [
    { text: "", value: "actions" },
    { text: "Filename", value: nameof<VesselEnquiryDocumentModel>("filename") },
    {
      text: "Reference",
      value: nameof<VesselEnquiryDocumentModel>("reference")
    },
    {
      text: "Uploaded",
      value: nameof<VesselEnquiryDocumentModel>("createdUtc")
    }
  ];

  private async created() {
    await this.getDocuments();
  }

  @Watch("vesselEnquiryId", { immediate: true })
  private async getDocuments() {
    try {
      this.loading = true;
      const response = await Api.VesselEnquiryDocumentService.apiVesselenquirydocumentVesselEnquiryVesselEnquiryIdGet(
        this.vesselEnquiryId
      );
      this.documents = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch documents");
    } finally {
      this.loading = false;
    }
  }
}
